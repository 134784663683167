import React from 'react';
import { Title } from '../title/Title';
import { appAnalytics } from '../../../analytics';
import { SignUp } from '../../analytics';
import { getOneLink, getLink, isTouchDevice } from '../../utils';
import ContentCarousel from './components/carousel/Carousel';

import styles from './styles.module.scss';

export const Content = () => {
  const getUrl = () => {
    if (isTouchDevice()) {
      return getOneLink({
        mediaSource: 'website',
        campaign: 'content',
        retargeting: 'true',
      });
    } else {
      return getLink('https://get.loona.app?st=false&ps_offer=false', {
        utmSource: 'website',
        utmMedium: 'social',
        utmCampaign: 'index',
        utmContent: 'content',
      });
    }
  };

  const handleButtonClick = () => {
    appAnalytics.trackEvent(new SignUp());
    window.location.href = getUrl();
  };

  return (
    <div className={styles.container}>
      <Title className={styles.title}>
        Content so good {'\n'}
        <span>it will put you to sleep</span>
      </Title>

      <button className={styles.button} onClick={handleButtonClick}>
        <div className={styles.overlay} />
        Try for free
      </button>
      <ContentCarousel />
    </div>
  );
};
