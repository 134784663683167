import React from 'react';
import styles from './styles.module.scss';
import Logo from '../../../assets/logo_loona.svg';
import { Title } from '../title/Title';
import { useInView } from 'react-intersection-observer';
import { appAnalytics } from '../../../analytics';
import { SignUp } from '../../analytics';
import { getOneLink, getLink, isTouchDevice } from '../../utils';
import HeaderVideo from '../../../assets/loonaWeb/video.mp4';

export const Header = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const getUrl = () => {
    if (isTouchDevice()) {
      return getOneLink({
        mediaSource: 'website',
        campaign: 'header',
        retargeting: 'true',
      });
    } else {
      return getLink('https://get.loona.app?st=false&ps_offer=false', {
        utmSource: 'website',
        utmMedium: 'social',
        utmCampaign: 'index',
        utmContent: 'header',
      });
    }
  };

  const handleButtonClick = () => {
    appAnalytics.trackEvent(new SignUp());
    window.location.href = getUrl();
  };

  return (
    <header className={styles.header}>
      <img className={styles.logo} src={Logo} alt="Loóna" />
      <div className={styles.backgroundContainer}>
        <div className={styles.topGradient} />
        <video
          playsInline={true}
          autoPlay={true}
          muted={true}
          loop={true}
          className={styles.video}
          height="100%"
          width="100%"
        >
          <source src={HeaderVideo} type="video/mp4" />
        </video>
        <div className={styles.bottomGradient} />
      </div>
      <Title className={styles.title}>
        Say goodnight to racing thoughts <span>and drift off to sleep</span>
      </Title>
      <div
        ref={ref}
        className={`
          ${styles.description} 
          ${inView ? styles.animate : ''}
        `}
      >
        An award-winning app designed to gently occupy your mind with soothing
        interactive content, shedding the worries of today and setting you up
        for a serene tomorrow.
      </div>
      <button className={styles.button} onClick={handleButtonClick}>
        <div className={styles.overlay} />
        Try for free
      </button>
    </header>
  );
};
