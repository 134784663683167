import React from 'react';
import PillowImg from '../../../assets/loonaWeb/pillow.png';
import PillowImgMobile from '../../../assets/loonaWeb/mobile/pillow.png';
import GrassBg from '../../../assets/loonaWeb/grass_bg.png';
import GrassBgMobile from '../../../assets/loonaWeb/mobile/grass_bg.png';
import { TABLET_QUERY, useMediaQuery } from '../../../hooks/useMediaQuery';
import { appAnalytics } from '../../../analytics';
import { SignUp } from '../../analytics';
import { getOneLink, getLink, isTouchDevice } from '../../utils';
import styles from './styles.module.scss';

export const Pillow = () => {
  const isLargeScreen = useMediaQuery(TABLET_QUERY);

  const getUrl = () => {
    if (isTouchDevice()) {
      return getOneLink({
        mediaSource: 'website',
        campaign: 'pillow',
        retargeting: 'true',
      });
    } else {
      return getLink('https://get.loona.app?st=false&ps_offer=false', {
        utmSource: 'website',
        utmMedium: 'social',
        utmCampaign: 'index',
        utmContent: 'pillow',
      });
    }
  };

  const handleButtonClick = () => {
    appAnalytics.trackEvent(new SignUp());
    window.location.href = getUrl();
  };

  return (
    <div className={styles.container}>
      <img
        src={isLargeScreen ? PillowImg : PillowImgMobile}
        className={styles.pillow}
        alt="pillow"
      />
      <div className={styles.textWrapper}>
        <div className={styles.title}>
          {'Power on\n'} <span>to power off</span>
        </div>
        <div className={styles.description}>Choose your dream plan</div>
        <button className={styles.button} onClick={handleButtonClick}>
          <div className={styles.overlay} />
          Try for free
        </button>
      </div>
      <img
        className={styles.grass}
        src={isLargeScreen ? GrassBg : GrassBgMobile}
        alt=""
      />
    </div>
  );
};
